/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const ReactDOM = require('react-dom')

// replacing hydration with render to workaround password-protect bug
// https://gitlab.com/mkit/open-source/gatsby-theme-password-protect/-/issues/2
// https://github.com/gatsbyjs/gatsby/issues/17914
export function replaceHydrateFunction() {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback);
  }
}
